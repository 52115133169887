.auto-bot-list {
  height: 75vh;; /* Make the parent container take the full screen height */
}
.auto-bot-lists {
  height: 100%; /* Make the parent container take the full screen height */
}
.auto-bot-lists .ant-table {
  
  justify-content: center;
  align-items: center;
  height: 50vh; 
  margin: 0 auto; 
  max-width: 1200px; 
  width: 100%; 
}
.auto-bot-lists .ant-table-cell {
  padding: 8px; 
}

.auto-bot-lists .ant-table-thead > tr > th {
  text-align: center; 
  background-color: #000; 
  color: #ffffff; 
  padding: 14px; 
}

.auto-bot-lists .ant-table-tbody > tr > td {
  text-align: center; 
  padding: 8px; 
}
.auto-bot-list .ant-table {
  
  justify-content: center;
  align-items: center;
  height: 60vh; 
  margin: 0 auto; 
  max-width: 1200px; 
  width: 100%; 
  
}
.custom-input {
  width: 200px; /* Adjust width as needed */
}
.auto-bot-list .ant-table-cell {
  padding: 8px; 
}

.auto-bot-list .ant-table-thead > tr > th {
  text-align: center; 
  background-color: #000; 
  color: #ffffff; 
  padding: 14px; 
}

.auto-bot-list .ant-table-tbody > tr > td {
  text-align: center; 
  padding: 8px; 
}
.custom-select .ant-select-selector {
  max-width: 200px; /* Adjust the width as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom-select .ant-select-selection-item {
  max-width: 150px; /* Adjust the width as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.status-button {
  transition: transform 0.2s, box-shadow 0.2s;
  border-radius: 10px; /* For a rounded, 3D effect */
  perspective: 1000px;
  position: relative;
}

.status-button:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

/* 3D pressed effect */
.status-button:active {
  transform: translateY(2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Styling for the loading icon inside the button */
.loading-icon {
  margin-right: 3px;
}
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
